<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="close" content-class="rounded-xl">
    <v-card class='pt-7 pb-4 justify-center rounded-xl'>
      <v-card-text>{{ $t('newPassword.success') }}</v-card-text>
      <v-card-actions class='justify-center'>
        <v-btn @click="close" color="primary" depressed rounded> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 300
    }
  }),
  methods: {
    close () {
      this.dialog = false
    },
    show () {
      this.dialog = true
    }
  }
}
</script>
