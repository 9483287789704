<template>
  <div>
    <AppBar :title="$t('account.title')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :label="$t('account.name')"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="surname"
                :label="$t('account.surname')"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="email"
                :label="$t('account.email')"
                disabled
              ></v-text-field>
              <v-text-field
                v-model='center'
                :label="$t('account.shoppingCenter')"
                disabled>
              </v-text-field>
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="12" offset-md="0" md="1">
            <v-btn depressed color="primary" @click="showChangePasswordDialog" :disabled="loading" block rounded> {{ $t('account.changePassword') }} </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <ChangeCashierPassword ref="changeCashierPassword" :showSuccessDialog="showSuccessDialog"></ChangeCashierPassword>
      <ChangeCashierPasswordSuccess ref="changeCashierPasswordSuccess"></ChangeCashierPasswordSuccess>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import ChangeCashierPassword from '@/components/dialogs/ChangeCashierPassword.vue'
import ChangeCashierPasswordSuccess from '@/components/dialogs/ChangeCashierPasswordSuccess.vue'

export default {
  name: 'Account',
  components: { ChangeCashierPasswordSuccess, ChangeCashierPassword },
  data () {
    return {
      loading: false,
      name: '',
      surname: '',
      email: '',
      center: null
    }
  },
  computed: {
  },
  async created () {
    await this.getCashierDetails()
  },
  methods: {
    async getCashierDetails () {
      this.loading = true
      try {
        const { data } = await Service.get('Cashier/CashierDetails')
        this.name = data.name
        this.surname = data.surname
        this.email = data.email
        this.center = data.shoppingCenter
      } catch (error) {
      }
      this.loading = false
    },
    showChangePasswordDialog () {
      this.$refs.changeCashierPassword.show()
    },
    showSuccessDialog () {
      this.$refs.changeCashierPasswordSuccess.show()
    }
  }
}
</script>
